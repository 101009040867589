import { notification } from "antd";

export const API_ROOT = `${process.env.REACT_APP_API_ROOT}`;

export const openNotification = (type, message, title = "") => {
  if (type === "success") {
    notification["success"]({
      message: `Success`,
      description: `${message}`,
    });
  } else {
    notification["error"]({
      message: title === "" ? "Something went wrong" : title,
      description: `${message}`,
    });
  }
};
