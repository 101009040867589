import React, { Component, Suspense, } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import {
  Layout,
  
  Button,
  
} from "antd";
import packageJson from "../package.json";
import Auth from "./pages/auth";
import axios from "axios";
import Dashboard from "./pages/dashboard";
import logo from "./shared/logo.jpeg";
import District from "./pages/district";
import {
  ProfileOutlined,
  HomeOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

axios.interceptors.request.use(
  function (config) {
    // config.headers.token = localStorage.token;
    config.headers["Authorization"] = `Bearer ${localStorage.token}`;
    return config;
  },
  function (error) {
    // openNotification("error", `Something went wrong`);
    console.log("error", error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // if (response.status === 401) {
    //   //TODO: API returns "token Missing" for other things too. So can't use response interceptor for route gaurding
    //   localStorage.clear();
    //   window.location.href = "/";
    // }
    return response;
  },
  function (error) {
    if (error.message.includes("401")) {
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

const ProtectedRoutes = (props) => {
  let history = useHistory();
  if (!localStorage.token) {
    // TODO remove this before prod
    history.push(`/`);
    return props.children;
  } else {
    return props.children;
  }
};
const Logout = () => {
  const history = useHistory();
  const location = useLocation();
  if (location.pathname !== '/') {
    return (
      <>
        {location.pathname === "/dashboard" ? (
          <Button
            type="link"
            onClick={() => {
              history.push("/district");
            }}
            ghost
            style={{ color: "white" }}
            icon={<ProfileOutlined />}
          >
            Reports
          </Button>
        ) : (
          <Button
            type="link"
            onClick={() => {
              history.push("/dashboard");
            }}
            ghost
            style={{ color: "white" }}
            icon={<HomeOutlined />}
          >
            Home
          </Button>
        )}
        <Button
          type="link"
          onClick={() => {
            localStorage.clear();
            history.push("/");
          }}
          icon={<LogoutOutlined />}
          ghost
          style={{ color: "#ff7875", fontWeight: 500 }}
        >
          Logout
        </Button>
      </>
    );
  }
  return null;
};
export default class Main extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Layout style={{ minHeight: "100vh" }}>
                <Layout.Header
                  style={{
                    background: "#034e29",
                    height: "75px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <img src={logo} alt="logo" width="5%" />
                      <div
                        style={{
                          color: "white",
                          marginLeft: "1rem",
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                        }}
                      >
                        முதலமைச்சரின் அம்மா மினி கிளினிக்
                      </div>
                    </div>

                    <div>
                      <Logout />
                    </div>
                  </span>
                </Layout.Header>
                <Layout.Content>
                  <Route exact path="/" component={Auth} />
                  <ProtectedRoutes>
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/district" component={District} />
                  </ProtectedRoutes>
                </Layout.Content>
                <Layout.Footer>
                  <div style={{ fontSize: "1.5rem" }}>
                    Powered by{" "}
                    <a
                      href="https://slashdr.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      SlashDr
                    </a>{" "}
                    <small>(v{packageJson.version})</small>
                  </div>
                </Layout.Footer>
              </Layout>
            </Switch>
          </Suspense>
        </BrowserRouter>
      </div>
    );
  }
}
