import React from "react";
import { CSVLink } from "react-csv";
import { FileExcelOutlined } from "@ant-design/icons";
function DownloadCSV(props) {
  const headers = [
    { label: "District", key: "district" },

    { label: `Inauguration status - Proposed`, key: "proposed" },
    { label: `Inaugurated on ${props.date}`, key: "inaugurated" },
    { label: `Inaugurated upto ${props.date}`, key: "inaugurated_upto" },
    {
      label: `Rate of Inauguration as on ${props.date} (%)`,
      key: "inaugurated_rate",
    },

    { label: `OP Perf. Active clinics on ${props.date}`, key: "clinics_on" },
    { label: `OP Perf. Morning on ${props.date}`, key: "morning_on" },
    { label: `OP Perf. Evening on ${props.date}`, key: "evening_on" },
    { label: `OP Perf. Total on ${props.date}`, key: "total_on" },

    { label: `OP Perf. Morning upto ${props.date}`, key: "morning_upto" },
    { label: `OP Perf. Evening upto ${props.date}`, key: "evening_upto" },
    { label: `OP Perf. Total upto ${props.date}`, key: "total_upto" },

    { label: `Avg. OP/day/Mini Clinic - Morning`, key: "morning_avg" },
    { label: `Avg. OP/day/Mini Clinic - Evening`, key: "evening_avg" },
    { label: `Avg. OP/day/Mini Clinic - Total`, key: "total_avg" },
  ];
  return (
    <div>
      <CSVLink
        data={props.data}
        headers={headers}
        filename={`Mini Clinic report on ${props.date}.csv`}
        className="ant-btn ant-btn-primary"
      >
        <FileExcelOutlined />{" "}
        Download
      </CSVLink>
    </div>
  );
}

export default DownloadCSV;
