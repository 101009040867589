import React from "react";
import axios from "axios";
import { API_ROOT } from "../shared/api";
import TodayVisitsByDistrict from '../components/todayVisitsByDistrict'
export default class Dashboard extends React.Component {
  state = {
    visits: [],
  };
  getTodayVisits = () => {
    axios
      .get(`${API_ROOT}/today_visits`)
      .then((res) => this.setState({ visits: res.data }))
      .catch((err) => console.error(err));
  };
  componentDidMount() {
    this.getTodayVisits();
  }
  render() {
    return (
      <div style={{ margin: "1rem" }}>
        <TodayVisitsByDistrict data={this.state.visits} />
      </div>
    );
  }
}
