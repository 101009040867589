import React from "react";
import DistrictReport from '../components/disctrictReport';

export default class District extends React.Component {
  render() {
    return (
      <div style={{ margin: "1rem" }}>
        <DistrictReport />
      </div>
    );
  }
}
