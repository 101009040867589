import React from "react";
import { API_ROOT } from "../shared/api";
import { openNotification } from "../shared/notification";
import { Form, Input, Button, Row, Col, Card } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      doctors: [],
      doctorId: null,
      clinicId: null,
      doctorName: "",
    };
    this.update = (values) => this.setState(values);
  }

  handleSubmit = (values) => {
    this.setState({
      loading: true,
    });
    let fetchData = {
      method: "POST",
      // mode: 'no-cors',
      // credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic ${btoa(values.username + ":" + values.password)}`,
      },
    };

    fetch(`${API_ROOT}/admin_token`, fetchData)
      .then((res) => res.json())
      .then((data) => {
        console.log("data", data);
        localStorage.setItem('token', data.token);
        this.props.props.history.push(`/dashboard`);
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          loading: false,
        });
        openNotification("error", "Something went wrong", "Error");
      });
  };

  render() {
    return (
      <Row>
        <Col span={10} offset={7}>
          <Card
            title="Login to Mini Clinic Dashboard"
            headStyle={{ textAlign: "center" }}
            bordered={false}
            style={{ minHeight: "30vh", marginTop: '5rem' }}
          >
            <Row gutter={16} type="flex" justify="space-around" align="middle">
              <Col span={12}>
                <Form
                  name="normal_login"
                  ref={this.formRef}
                  className="login-form"
                  onFinish={this.handleSubmit}
                  size="large"
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Username!",
                      },
                    ]}
                  >
                    <Input
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Username"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Password!",
                      },
                    ]}
                  >
                    <Input.Password
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Password"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      block
                      loading={this.state.loading}
                    >
                      Log in
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}
// Login.contextType = ClinicContext;
export const LoginForm = Login;
