import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_ROOT } from "../shared/api";
import { Col, DatePicker, Row, Table } from "antd";
import moment from "moment";
import DownloadCSV from "./downloadCSV";

function DistrictReport(props) {
  const formatAPI = "DD-MM-YYYY"; //01-01-2021
  const formatUI = "Do MMM YYYY"; //1st Jan 2021
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);
  const [date, setdate] = useState(moment().subtract(1, "days"));
  const formatDate = moment(date).format(formatUI);

  useEffect(() => {
    setloading(true);
    axios
      .get(
        `${API_ROOT}/daily_district_report?date=${moment(date).format(formatAPI)}`
      )
      .then((res) => {
        setdata(res.data.stats);
        setloading(false);
      })
      .catch((err) => {
        console.error(err);
        setloading(false);
      });
  }, [date]);

  const Tabletitle = () => {
    return (
      <div>
        <Row justify="space-between">
          <Col>
            Mini Clinic report on {": "}
            <DatePicker
              onChange={(momentDate, dateString) => setdate(momentDate)}
              format={formatUI}
              defaultValue={date}
            />
          </Col>
          <Col>
            <DownloadCSV data={data} date={moment(date).format(formatUI)} />
          </Col>
        </Row>
      </div>
    );
  };
  const colWidth = 100/14
  const columns = [
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      className: "district",
      render: (text, record) => <span>{text}</span>,
      width: `${colWidth}%`,
    },
    {
      title: `Inauguration status on ${formatDate}`,
      children: [
        {
          title: "Proposed",
          dataIndex: "proposed",
          key: "proposed",
          align: "right",
          width: `${colWidth}%`,
          render: (text, record) => <span>{text.toLocaleString()}</span>,
        },
        {
          title: "Today",
          dataIndex: "inaugurated",
          key: "inaugurated",
          align: "right",
          width: `${colWidth}%`,
          render: (text, record) => <span>{text.toLocaleString()}</span>,
        },
        {
          title: "To date",
          dataIndex: "inaugurated_upto",
          key: "inaugurated_upto",
          align: "right",
          width: `${colWidth}%`,
          render: (text, record) => <span>{text.toLocaleString()}</span>,
        },
        {
          title: "Rate (%)",
          dataIndex: "inaugurated_rate",
          key: "inaugurated_rate",
          align: "right",
          width: `${colWidth}%`,
          render: (text, record) => <span>{`${text}%`}</span>,
        },
      ],
    },
    {
      title: `OP Performance on ${formatDate}`,
      children: [
        {
          title: "Morning",
          dataIndex: "morning_on",
          key: "morning_on",
          align: "right",
          width: `${colWidth}%`,
          render: (text, record) => <span>{text.toLocaleString()}</span>,
        },
        {
          title: "Evening",
          dataIndex: "evening_on",
          key: "evening_on",
          align: "right",
          width: `${colWidth}%`,
          render: (text, record) => <span>{text.toLocaleString()}</span>,
        },
        {
          title: "Total",
          dataIndex: "total_on",
          key: "total_on",
          align: "right",
          width: `${colWidth}%`,
          className: "totalGrouped",
          render: (text, record) => <span>{text.toLocaleString()}</span>,
        },
      ],
    },
    {
      title: `OP Performance upto ${formatDate}`,
      children: [
        {
          title: "Morning",
          dataIndex: "morning_upto",
          key: "morning_upto",
          align: "right",
          width: `${colWidth}%`,
          render: (text, record) => <span>{text.toLocaleString()}</span>,
        },
        {
          title: "Evening",
          dataIndex: "evening_upto",
          key: "evening_upto",
          align: "right",
          width: `${colWidth}%`,
          render: (text, record) => <span>{text.toLocaleString()}</span>,
        },
        {
          title: "Total",
          dataIndex: "total_upto",
          key: "total_upto",
          align: "right",
          width: `${colWidth}%`,
          render: (text, record) => <span>{text.toLocaleString()}</span>,
          className: "totalGrouped",
        },
      ],
    },
    {
      title: "Avg. OP/day/ Mini Clinic",
      children: [
        {
          title: "Morning",
          dataIndex: "morning_avg",
          key: "morning_avg",
          align: "right",
          width: `${colWidth}%`,
          render: (text, record) => <span>{text.toLocaleString()}</span>,
        },
        {
          title: "Evening",
          dataIndex: "evening_avg",
          key: "evening_avg",
          align: "right",
          width: `${colWidth}%`,
          render: (text, record) => <span>{text.toLocaleString()}</span>,
        },
        {
          title: "Total",
          dataIndex: "total_avg",
          key: "total_avg",
          align: "right",
          width: `${colWidth}%`,
          className: "totalGrouped",
          render: (text, record) => <span>{text.toLocaleString()}</span>,
        },
      ],
    },
  ];

  return (
    <div style={{ width: "100%", margin: "0px auto" }}>
      <Table
        title={() => <Tabletitle />}
        className="table-striped-rowss"
        id="table-to-xls"
        dataSource={data}
        columns={columns}
        pagination={false}
        size="small"
        bordered
        loading={loading}
      />
    </div>
  );
}

export default DistrictReport;
