import React, { useEffect, useState } from "react";
import { Table } from "antd";
import _ from "lodash";
import moment from "moment";
function TodayVisitsByDistrict(props) {
  const [morning, setmorning] = useState(0);
  const [evening, setevening] = useState(0);
  const [totalVisits, settotalVisits] = useState(0);
  const [diagnosis, setdiagnosis] = useState(0);
  const [prescription, setprescription] = useState(0);

  const columns = [
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      width: "20%",
      className: "district",
      render: (text, record) => (
        <span>
          {text}{" "}
          <small style={{ color: "gray" }}>({record.values.length})</small>
        </span>
      ),
    },
    {
      title: `Morning (${morning.toLocaleString()})`,
      dataIndex: "morning",
      key: "morng",
      width: "20%",
      align: "right",
      className: "morning",
    },
    {
      title: `Evening (${evening.toLocaleString()})`,
      dataIndex: "evening",
      key: "evng",
      width: "20%",
      align: "right",
      className: "evening",
    },
    {
      title: `Total (${(morning + evening).toLocaleString()})`,
      dataIndex: "total",
      key: "total",
      width: "20%",
      align: "right",
      className: "total",
    },
    {
      title: `Diagnosis (${diagnosis.toLocaleString()})`,
      dataIndex: "diagnosis",
      key: "diagnosis",
      width: "20%",
      align: "right",
      className: "diagnosis",
    },
    {
      title: `Medicine (${prescription.toLocaleString()})`,
      dataIndex: "prescription",
      key: "prescription",
      width: "20%",
      align: "right",
      className: "prescription",
    },
  ];
  const [data, setdata] = useState([]);

  const nestedColumns = [
    {
      title: "Clinics",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (text, record) => <span>- {text}</span>,
    },
    {
      title: "Morning",
      dataIndex: "morng",
      key: "morng",

      align: "right",
    },
    {
      title: "Evening",
      dataIndex: "evng",
      key: "evng",

      align: "right",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      align: "right",
      className: "totalNested",
    },
    {
      title: "Diagnosis",
      dataIndex: "diagnosis",
      key: "diagnosis",

      align: "right",
    },
    {
      title: "Medicine",
      dataIndex: "prescription",
      key: "prescription",

      align: "right",
    },
  ];

  useEffect(() => {
    let dataParent = props.data;
    let restrctData = [];
    let grouped = _.chain(dataParent)
      .groupBy("district")
      .map((value, key) => ({ district: key, values: value }))
      .value();
    let morningTotal = () => {
      return _.reduce(
        props.data,
        function (sum, n) {
          return sum + n.morng;
        },
        0
      );
    };
    let eveningTotal = () => {
      return _.reduce(
        props.data,
        function (sum, n) {
          return sum + n.evng;
        },
        0
      );
    };
    let diagnosisTotal = () => {
      return _.reduce(
        props.data,
        function (sum, n) {
          return sum + n.diagnosis;
        },
        0
      );
    };
    let prescriptionTotal = () => {
      return _.reduce(
        props.data,
        function (sum, n) {
          return sum + n.prescription;
        },
        0
      );
    };

    setmorning(morningTotal);
    setevening(eveningTotal);
    settotalVisits(morningTotal() + eveningTotal());
    setdiagnosis(diagnosisTotal);
    setprescription(prescriptionTotal);

    // individual reduce
    

    let morningReduce = (arr) => {
      return _.reduce(
        arr,
        function (sum, n) {
          return sum + n.morng;
        },
        0
      );
    };
    let eveningReduce = (arr) => {
      return _.reduce(
        arr,
        function (sum, n) {
          return sum + n.evng;
        },
        0
      );
    };
    let diagnosisReduce = (arr) => {
      return _.reduce(
        arr,
        function (sum, n) {
          return sum + n.diagnosis;
        },
        0
      );
    };
    let prescriptionReduce = (arr) => {
      return _.reduce(
        arr,
        function (sum, n) {
          return sum + n.prescription;
        },
        0
      );
    };
    grouped.map((item, index) => {
      restrctData.push({
        ...item,
        key: `district-${index + 1}`,
        morning: morningReduce(item.values),
        evening: eveningReduce(item.values),
        total: morningReduce(item.values) + eveningReduce(item.values),
        diagnosis: diagnosisReduce(item.values),
        prescription: prescriptionReduce(item.values),
        values: item.values.map((val, valIndex) => {
          return {
            ...val,
            key: `district-${index + 1}-val-${valIndex + 1}`,
            total: val.morng + val.evng,
          };
        }),
      });
    });
    console.log("restrctData", restrctData);
    setdata(restrctData);
  }, [props.data]);
  return (
    <div style={{ width: "90%", margin: "0px auto" }}>
      <Table
        title={() => (
          <span>
            Active Clinics: {props.data.length} | Total visits{" "}
            {totalVisits.toLocaleString()} on {moment().format("Do MMM YYYY")}
          </span>
        )}
        className="table-striped-rows"
        dataSource={data}
        columns={columns}
        expandedRowRender={(record, i) => (
          <Table
            size="small"
            columns={nestedColumns}
            dataSource={record.values}
            pagination={false}
            bordered
          />
        )}
        pagination={false}
        size="large"
      />
    </div>
  );
}

export default TodayVisitsByDistrict;
