import React from "react";
import { LoginForm } from "../components/login";
import { API_ROOT } from "../shared/api";

const Auth = (props) => (
  <div>
    <LoginForm title="Login" base_url={API_ROOT} props={props} />
  </div>
);
export default Auth;
